/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
.statusColumnWidth { width:180px; }
.groupidColumnWidth { width:100px; } 
.dateColumnWidth { width:240px; max-width:240px; }
.shortDateColumnWidth { width:120px; }
.productColumnWidth { width: 280px; }
.localit-hidden thead tr th:first-child{display: none;}
.localit-hidden tbody tr td:first-child{display: none;}
.csvtable { table-layout: fixed; }
.csvtable td, .csvtable th { overflow: hidden; }

button:focus {
  outline: 0 !important;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.orgdir-table{
  width: 100%
}

.mat-mdc-form-field {
  font-size: 14px;
  width: 100%;
}

.container {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
}

.col-12:focus {
  outline: none;
}

.btn-outline-customBlue {
    color: white;
    background-image: none;
    background-color: transparent;
    border-color:white;
  }
  
  .btn-outline-customBlue:hover {
    color: #0074D8;
    background-color: white;
    border-color: white;
  }
  
  .btn-outline-customBlue:focus, .btn-outline-customBlue.focus {
    color: #0074D8;
    background-color: white;
    border-color: white;
  }
  
  .btn-outline-customBlue:active, .btn-outline-customBlue.active,
  .open > .btn-outline-customBlue.dropdown-toggle {
    color: #0074D8;
    background-color: white;
    border-color: white;
  }
  
  .btn-outline-customBlue:active:hover, .btn-outline-customBlue:active:focus, .btn-outline-customBlue:active.focus, .btn-outline-customBlue.active:hover, .btn-outline-customBlue.active:focus, .btn-outline-customBlue.active.focus,
  .open > .btn-outline-customBlue.dropdown-toggle:hover,
  .open > .btn-outline-customBlue.dropdown-toggle:focus,
  .open > .btn-outline-customBlue.dropdown-toggle.focus {
    color: #fff;
    background-color: #a1a1a1;
    border-color: #8c8c8c;
  }
  
  .btn-outline-customBlue.disabled:focus, .btn-outline-customBlue.disabled.focus, .btn-outline-customBlue:disabled:focus, .btn-outline-customBlue:disabled.focus {
    border-color: white;
  }
  
  .btn-outline-customBlue.disabled:hover, .btn-outline-customBlue:disabled:hover {
    border-color: white;
  }
  
  /* Angular Material Icon Style Modification */
  .material-icons.md-18 { font-size: 18px; }
  .material-icons.md-24 { font-size: 24px; }
  .material-icons.md-36 { font-size: 36px; }
  .material-icons.md-48 { font-size: 48px; }
  
  .material-icons.primaryBlue { color: #0074D8; }
  
  .material-icons.primaryBlue.pointer:hover {
    color: #025aa5;
  }
  
  .material-icons.iconRed { color: #d32f2f }
  
  .material-icons.iconRed.pointer:hover {
    color: #b71c1c;
  }
  
  .material-icons.iconGreen { color: #689f38 }
  
  .material-icons.iconGreen.pointer:hover {
    color: #33691e;
  }
  
  .material-icons.pointer { 
    cursor: pointer;
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */ 
    user-select: none; 
  }

  #game-area {
    overflow: hidden;
    position: relative;
    width: 100vh;
    height: 100vh;
    margin: 0 auto 0 auto;
    background-color: #0275d8;
    color: #fff;
  }
  
  .space-invader {
    transition: left .1s, top .1s;
    position: absolute;
    width: 5%;
    height: 5%;
  }
  
  .tank {
    transition: left .1s, top .1s;
    position: absolute;
    width: 5%;
    height: 5%;
  }
  
  .bullet {
    transition: left .1s, top .1s;
    position: absolute;
    width: 5%;
    height: 5%;
  }
  
  .error-message {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    color:rgba(3, 255, 3, 1);
    transform: translate(-50%, -50%);
    font-size: 27px;
  }

  .mat-toolbar.mat-primary{background:#009de9;color:#fff}
  .mat-primary .mat-pseudo-checkbox-indeterminate{background:#009de9}
  .mat-badge-content{color:#fff;background:#009de9}
  .mat-mdc-button.mat-primary,.mat-mdc-icon-button.mat-primary,.mat-mdc-outlined-button.mat-primary{color:#009de9}
  .mat-mdc-unelevated-button.mat-primary,.mat-mdc-raised-button.mat-primary,.mat-mdc-fab.mat-primary,.mat-mdc-mini-fab.mat-primary{background-color:#009de9}
  .mat-mdc-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element{background:#009de9}
  .mat-calendar-body-selected{background-color:#009de9;color:#fff}
  .mat-datepicker-toggle-active{color:#009de9}
  .mat-icon.mat-primary{color:#009de9}
  .mat-mdc-input-element{caret-color:#009de9}
  .mat-mdc-progress-spinner circle,.mat-mdc-progress-spinner circle{stroke:#009de9}
  .mat-mdc-radio-button.mat-primary .mat-radio-inner-circle,.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple{background-color:#009de9}
  .mat-mdc-slide-toggle.mat-primary.mat-checked .mat-ripple-element{background-color:#009de9}
  .mat-mdc-tab-group.mat-background-primary .mat-mdc-tab-header,.mat-tab-group.mat-background-primary .mat-mdc-tab-links,.mat-tab-group.mat-background-primary .mat-tab-header-pagination,.mat-mdc-tab-nav-bar.mat-background-primary .mat-tab-header,.mat-tab-nav-bar.mat-background-primary .mat-tab-links,.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination{background-color:#009de9}